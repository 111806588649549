<template>
    <div>
        <main-nav fixed></main-nav>
        <l-right-contact></l-right-contact>

        <div class="banner">
            <img src="@/assets/images/fashion/qfi_banner_title.png" alt="" class="banner-text">
        </div>

        <l-barrage></l-barrage>
        <l-wtb></l-wtb>

        <div class="green">
            <img class="green-title-img" src="@/assets/images/fashion/g-t1.png" alt="">
            <p class="green-title">选择量化时尚学院，你将收获什么？</p>


            <div class="green-right">
                <img ondragstart="return false" src="https://static.ycsd.work/site/fashion/gl.png" alt="">
                <div class="green-right-title">提升。</div>
                <div class="green-right-desc">干货知识，时髦穿搭功底提升</div>
                <div class="green-line"></div>
                <div class="green-content">如果你热爱时尚穿搭，对服饰穿搭新事物感兴趣，爱美并且希望<span>学习搭配干货知识</span>，学习完课程后，你将对于服饰搭配构成的四大元素熟练掌握，纯熟运用，<span>收获全新的、体系化的专业变美逻辑思路</span>，不再是盲目追随潮流的小白，而是成为风格格调的造物主。
                </div>
            </div>

            <div class="clearfix"></div>
        </div>

        <div class="white">
            <img ondragstart="return false" src="https://static.ycsd.work/site/fashion/white_1.png" alt="">
            <div class="white-title">吸金。</div>
            <div class="white-desc">进入“时髦吸金新职业”蓝海</div>
            <div class="white-line"></div>
            <div class="white-content">视觉经济时代来临，消费者审美升级，很多行业都将迎来颠覆性的改变和重造，这蕴涵着巨大的<span>经济潜力</span>和个人<span>商业价值</span>，新经济形势下的服务业必将大放异彩！学习完专业课程，预示着你进入了一个时髦吸金新职业的蓝海，<span>具备了进入时尚圈新行业的可能</span>，每天与时尚为伴，真正成为了一名“含金量极高”的时尚背后操盘手，时髦算法优化师。
            </div>
        </div>

        <div class="white2">
            <img ondragstart="return false" src="https://static.ycsd.work/site/fashion/white-3.png" alt="">
            <div class="white-title">突破。</div>
            <div class="white-desc">为传统行业打开新思路，突破困局</div>
            <div class="white-line"></div>
            <div class="white-content">如果你从事服装服饰、美容美发等传统行业又或是一名时尚博主、买手店经营者、线上网红店主，受困于传统行业的日益下滑，量化时尚课程将为你的工作和事业<span>开启新的可能</span>，注入新鲜的血液，让你将时尚、美学背后科学的干货知识与自身行业融合，让你获得独特的行业优势，<span>在激烈的市场竞争中脱颖而出</span>。
            </div>
        </div>

        <l-spc></l-spc>
        <l-alumni></l-alumni>

        <l-footer></l-footer>
        <l-icp></l-icp>
    </div>
</template>

<script>
    import MainNav from "../../components/MainNav";
    import LBarrage from "../../components/LBarrage";
    import LWtb from "../../components/LWtb";
    import LFooter from "../../components/LFooter";
    import LIcp from "../../components/LIcp";
    import LAlumni from "../../components/LAlumni";
    import LSpc from "../../components/LSpc";
    import LRightContact from "../../components/LRightContact";
    import meta from "../../meta";

    export default {
        name: "QuantitativeFashion",
        components: {LRightContact, LSpc, LAlumni, LIcp, LFooter, LWtb, LBarrage, MainNav},
        metaInfo: meta.appendTitle('量化时尚学院'),
    }
</script>

<style lang="less" scoped>
    .banner {
        height: 800px;
        background-size: contain;
        background-image: url("https://static.ycsd.work/site/fashion/qfi_banner.png");
        text-align: center;
    }

    .banner-text {
        width: 1000px;
        margin-top: 184px;
    }

    .green {
        padding-top: 268px;
        background-color: rgba(13, 57, 46, 1);
        margin-bottom: 45px;
    }

    .green-title-img {
        display: inline-block;
        width: 1120px;
        margin-bottom: 36px;
    }

    .green-title {
        display: inline-block;
        text-align: left;
        width: 1120px;
        font-size: 60px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi, serif;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 82px;
        margin-bottom: 150px;
    }

    .green-right {
        padding-left: 848px;
        width: 1461px;
        padding-top: 300px;
        position: relative;

        img {
            width: 848px;
            position: absolute;
            left: 0;
            top: 300px;
            z-index: 1;
        }
    }

    .green-right-title {
        font-size: 100px;
        font-weight: 500;
        color: #F9F9F9;
        line-height: 140px;
        text-align: right;
        margin-bottom: 30px;
        margin-right: -60px;
    }

    .green-right-desc {
        text-align: right;
        font-size: 36px;
        font-weight: 500;
        color: #F9F9F9;
        line-height: 50px;
        margin-bottom: 40px;
    }

    .green-line {
        height: 2px;
        background-color: #F9F9F9;
        margin-bottom: 60px;
    }

    .green-content {
        text-align: left;
        font-size: 24px;
        font-weight: 400;
        color: rgba(205, 205, 205, 1);
        line-height: 60px;
        margin-bottom: 170px;
        padding-left: 126px;

        span {
            color: rgba(249, 249, 249, 1);
        }
    }

    .white {
        padding-top: 553px;
        background: #F9F9F9;
        padding-left: 458px;
        text-align: left;
        position: relative;
        padding-bottom: 200px;


        img {
            width: 848px;
            position: absolute;
            right: 0;
            top: 403px;
        }
    }

    .white-title {
        font-size: 100px;
        font-weight: 500;
        color: #1F7762;
        line-height: 140px;
        margin-bottom: 60px;
    }

    .white-desc {
        font-size: 36px;
        font-weight: 500;
        color: #333333;
        line-height: 50px;
        margin-bottom: 40px;
    }

    .white-line {
        height: 2px;
        background-color: #101010;
        margin-bottom: 60px;
        width: 700px;
    }

    .white-content {
        width: 487px;
        height: 529px;
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        line-height: 60px;
        margin-bottom: 220px;

        span {
            color: rgba(51, 51, 51, 1)
        }

    }

    .white2 {
        padding-top: 150px;
        padding-left: 848px;
        padding-bottom: 194px;

        position: relative;
        text-align: left;

        .white-title, .white-desc, .white-content {
            margin-left: 122px;
        }

        .white-desc {
            margin-bottom: 30px;
        }

        .white-line {
            width: 665px;
            margin-bottom: 50px;
        }

        .white-content {
            width: 529px;
            height: 420px;
            margin-bottom: 349px;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 848px;
        }
    }


</style>