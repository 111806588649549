<template>
    <div class="wtb-container">
        <img class="wtb-title" src="@/assets/images/fashion/wtb-t.png" alt="">

        <div class="wtb-cards">

            <template v-for="(card,index) in cards">

                <div @mouseover="flipCard(card)"
                     @click="flipCard(card)"
                     v-if="index < 17"
                     :key="index"
                     class="wtd-card "
                     :class="getClass(card,index)">
                    <div class="flipper">
                        <div class="front" :style="`background-image: url(${card.front.img})`">
                            {{ card.front.title }}
                        </div>

                        <div class="back" :style="`background-image: url(${card.back.img})`">
                            {{ card.back.title }}
                        </div>
                    </div>
                </div>

            </template>

        </div>


        <a class="link" href="/#">一键开启时髦吸金新职业</a>

    </div>
</template>

<script>
    export default {
        name: "LWtb",
        data() {
            return {
                titles: [
                    '时尚杂志编辑',
                    '时尚摄影师',
                    '时装模特',
                    '服装设计师',
                    '服装行业从业者',
                    '化妆造型师',
                    '时尚代购',
                    '服装搭配师',
                    '服装陈列师',
                    '时尚买手',
                    '穿搭达人',
                    '网红主播',
                    '主持人',
                    '时尚博主',
                    '时尚化妆师',
                    '形象管理师',
                    '私人形象管家',
                    '奢侈品管理',
                    '时尚公关',
                    '创意总监',
                    '艺术总监',
                    '形象顾问',
                    '时尚造型师',
                    '收纳整理师',
                    '气质女性',
                    '高阶衣品人士',
                    '网红服装店主',
                    '时尚自媒体策划',
                    '时尚自媒体运营',
                    '美业从业者',
                    '医美行业从业者',
                ],
                cards: [
                    {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/1.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/1.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/2.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/2.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/3.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/3.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/4.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/4.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/5.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/5.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/6.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/6.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/7.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/7.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/8.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/8.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/9.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/9.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/10.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/10.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/11.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/11.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/12.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/12.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/13.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/13.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/14.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/14.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/15.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/15.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/16.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/16.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/17.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/17.png',
                        }
                    }, {
                        front: {
                            img: 'https://static.ycsd.work//site/cards/front/18.png',
                        },
                        back: {
                            img: 'https://static.ycsd.work//site/cards/back/18.png',
                        }
                    }
                ]
            }
        },
        mounted() {
            this.randomTitles()
        },
        methods: {
            randomTitles() {
                this.cards.map((card) => {
                    card.front.title = this.titles.diffRandom()
                    card.back.title = this.titles.diffRandom()
                })
            },
            getClass(card, index) {
                let cla = {};

                cla['c' + (index + 1)] = true;

                cla['hover'] = card.filp;

                return cla;
            },
            flipCard(card) {

                if (card.filping) return

                card.filping = true
                card.filp = !card.filp

                this.$forceUpdate()

                setTimeout(() => {
                    card.filping = false
                }, 1000)
            }
        },
    }
</script>

<style lang="less" scoped>
    .wtb-container {
        background: #101010;
        padding-top: 100px;
        width: 100%;
        overflow: hidden;
    }

    .wtb-title {
        width: 582px;
        margin-bottom: 130px;
    }

    .wtb-cards {
        width: 1920px;
        height: 1182px;
        overflow: hidden;
        font-size: 36px;
        font-weight: 500;
        color: #F3F3F3;
        line-height: 50px;
        margin-bottom: 120px;
        position: relative;

        .front, .back {
            width: 100%;
            height: 100%;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            backface-visibility: hidden;
        }
    }

    /* entire container, keeps perspective */
    .wtd-card {
        perspective: 1000px;
        position: absolute;
    }

    /* flip the pane when hovered */
    /*.wtd-card:hover .flipper, .wtd-card.hover .flipper {*/
    .wtd-card.hover .flipper {
        transform: rotateX(180deg);
    }

    /* flip speed goes here */
    .flipper {
        transition: 0.6s;
        transform-style: preserve-3d;
        position: relative;

        width: 100%;
        height: 100%;
    }

    /* hide back of pane during swap */
    .front, .back {
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }

    /* front pane, placed above back */
    .front {
        z-index: 2;
    }

    /* back, initially hidden pane */
    .back {
        transform: rotateX(180deg);
    }

    .c1 {
        width: 794px;
        height: 380px;
        top: 0;
        left: -48px;
    }

    .c2 {
        width: 692px;
        height: 180px;
        top: 0;;
        left: 766px;
    }

    .c3 {
        left: 1478px;
        top: 0;
        width: 794px;
        height: 180px;
    }

    .c4 {
        width: 691px;
        height: 180px;
        top: 400px;
        left: -454px;
    }

    .c5 {
        width: 488px;
        height: 180px;
        top: 400px;
        left: 257px;
    }

    .c6 {
        width: 489px;
        height: 380px;
        left: 766px;
        top: 200px;
    }

    .c7 {
        width: 400px;
        height: 180px;
        left: 1275px;
        top: 200px;
    }

    .c8 {
        width: 795px;
        height: 180px;
        left: 1695px;
        top: 200px;
    }

    .c9 {
        width: 794px;
        height: 180px;
        left: 1275px;
        top: 400px;
    }

    .c10 {
        width: 692px;
        height: 180px;
        left: -47px;
        top: 600px;
    }

    .c11 {
        width: 794px;
        height: 180px;
        left: 665px;
        top: 600px;
    }

    .c12 {
        width: 794px;
        height: 380px;
        left: 1479px;
        top: 600px;
    }

    .c13 {
        width: 489px;
        height: 380px;
        left: -47px;
        top: 800px;
    }

    .c14 {
        width: 400px;
        height: 180px;
        top: 800px;
        left: 462px;
    }

    .c15 {
        width: 577px;
        height: 180px;
        top: 800px;
        left: 882px;
    }

    .c16 {
        width: 794px;
        height: 180px;
        top: 1000px;
        left: 462px;
    }

    .c17 {
        width: 692px;
        height: 180px;
        top: 1000px;
        left: 1280px;
    }

    .link {
        display: inline-block;
        padding: 16px 116px;
        font-size: 28px;
        font-weight: 500;
        color: #333333;
        background: #F9F9F9;
        box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.5), 0 13px 28px 0 rgba(0, 0, 0, 0.33);
        text-shadow: 0 2px 50px rgba(0, 0, 0, 0.5);
        margin-bottom: 100px;
    }

</style>