<template>
    <div class="alumni-container">
        <div class="alumni-title">
            <img src="@/assets/images/fashion/dkt.png" alt="">
        </div>
        <div class="alumni">

            <div v-for="(barrage,k) in barragesDeck1" :key="k" class="barrage deck1" :class="barrage['class']">
                <img :src="barrage['image']" alt="">
                <div class="barrage-text">
                    <div class="name">{{ barrage['name']}}</div>
                    <div class="summary">{{ barrage['summary']}}</div>
                </div>
            </div>

            <div v-for="(barrage,k) in barragesDeck2" :key="k" class="barrage deck2" :class="barrage['class']">
                <img :src="barrage['image']" alt="">
                <div class="barrage-text">
                    <div class="name">{{ barrage['name']}}</div>
                    <div class="summary">{{ barrage['summary']}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LAlumni",
        data() {
            return {
                interval: null,
                interval2: null,
                barrages: [
                    {
                        image: 'https://static.ycsd.work/site/lal/1.png',
                        name: '陈信宏',
                        summary: '服装搭配师 月薪10k'
                    }, {
                        image: 'https://static.ycsd.work/site/lal/2.png',
                        name: '陈信宏',
                        summary: '服装搭配师 月薪25k'
                    }, {
                        image: 'https://static.ycsd.work/site/lal/3.png',
                        name: '陈信宏',
                        summary: '服装搭配师 月薪30k'
                    }, {
                        image: 'https://static.ycsd.work/site/lal/4.png',
                        name: '陈信宏',
                        summary: '服装搭配师 月薪18k'
                    }, {
                        image: 'https://static.ycsd.work/site/lal/5.png',
                        name: '陈信宏',
                        summary: '服装搭配师 月薪23k'
                    }, {
                        image: 'https://static.ycsd.work/site/lal/6.png',
                        name: '陈信宏',
                        summary: '服装搭配师 月薪14k'
                    }, {
                        image: 'https://static.ycsd.work/site/lal/7.png',
                        name: '陈信宏',
                        summary: '服装搭配师 月薪21k'
                    }
                ],
                barragesDeck1: {},
                barragesDeck2: {},
                deckKey: 'barragesDeck1',

                num: 0,
                heights: [
                    'h1',
                    'h2',
                    'h3',
                    'h4',
                    'h5',
                ]
            };
        },
        mounted() {
            this.autoplay()
        },
        destroyed() {
            clearInterval(this.interval2);
            clearInterval(this.interval);
        },
        methods: {

            autoplay() {

                this.interval = setInterval(() => {
                    this.$nextTick(() => {
                        this.createBarrage()
                    })
                }, 3000)

                this.interval2 = setInterval(this.switchDeck, 60000);
            },

            createBarrage() {
                let k = this.createKey();
                let barrage = Object.assign({}, this.barrages.diffRandom());
                barrage['class'] = this.getHeight();
                this[this.deckKey][k] = barrage

                this.$forceUpdate()
            },

            getHeight() {
                let i = (this.num % 5);
                switch (i) {
                    case 1:
                        return 'h1';
                    case 2:
                        return 'h4';
                    case 3:
                        return 'h2';
                    case 4:
                        return 'h5';
                }
                return 'h3';
            },


            createKey() {
                return 'A' + (++this.num);
            },
            switchDeck() {

                let k = this.deckKey;
                this.deckKey = k === 'barragesDeck1' ? 'barragesDeck2' : 'barragesDeck1';

                setTimeout(() => {
                    this[k] = {}
                }, 20000)
            },
        }
    }
</script>

<style lang="less" scoped>
    .alumni-container {
        background-color: rgba(16, 16, 16, 1);
        padding-top: 100px;
        width: 100%;
        overflow: hidden;
    }

    .alumni-title {
        img {
            width: 403px;
        }
    }

    .alumni {
        position: relative;
        height: 1000px;
        background-image: url("../assets/images/fashion/pbg.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
    }

    .barrage {
        .barrage-text {
            padding: 16px 30px;
            background: rgba(0, 0, 0, 0.8);
            text-align: left;
            position: absolute;
            bottom: -100px;
            left: 0;
            right: 0;

            transition: bottom .3s;

            .name {
                font-size: 20px;
                font-weight: 500;
                color: #F9F9F9;
                line-height: 28px;
            }

            .summary {
                font-size: 18px;
                font-weight: 400;
                color: #F9F9F9;
                line-height: 25px;
            }
        }

        display: inline-block;
        position: absolute;
        left: 0;
        opacity: 0;
        overflow: hidden;

        &.small {

        }

        animation-name: barrageIn;
        animation-duration: 30s;
        animation-timing-function: linear;

        &:hover {
            animation-play-state: paused;
            z-index: 99;

            .barrage-text {
                bottom: 0;
            }
        }
    }

    @keyframes barrageIn {
        0% {
            opacity: 1;
            transform: translate(2000px, 0);
        }

        99% {
            opacity: 1;
            transform: translate(-2000px, 0);
        }
        100% {
            opacity: 0;
            transform: translate(-2000px, 0);
        }

    }

    .h1 {
        top: 64px;
    }

    .h2 {
        top: 215px;
    }

    .h3 {
        top: 382px;
    }

    .h4 {
        top: 552px;
    }

    .h5 {
        top: 592px;
    }

</style>