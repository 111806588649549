<template>
    <div class="barrage-container">
        <img class="img-title" src="@/assets/images/fashion/barr-t.png" alt="">
        <div>

            <div v-for="(barrage,k) in barragesDeck1" :key="k" class="barrage deck1" :class="barrage['class']">
                {{ barrage['label'] }}
            </div>

            <div v-for="(barrage,k) in barragesDeck2" :key="k" class="barrage deck2" :class="barrage['class']">
                {{ barrage['label'] }}
            </div>

        </div>

    </div>
</template>

<script>

    export default {
        name: "LBarrage",
        data() {
            return {
                interval: null,
                interval2: null,
                barrages: [
                    '只会copy博主整套穿搭 ，效果却强差人意',
                    '时尚品味度',
                    '穿搭底层逻辑一头雾水',
                    '土气的穿搭方式',
                    '衣橱爆满却总觉得无衣可穿',
                    '花钱买新衣却频频踩雷',
                ],
                barragesDeck1: {
                },
                barragesDeck2: {},
                deckKey: 'barragesDeck1',

                num: 0,
                heights: [
                    't175',
                    't340',
                    't850',
                    't670',
                    't466',
                ],
                sizes: [
                    'size1',
                    'size2',
                    'size3',
                ],
            };
        },
        mounted() {
            this.autoplay()
        },
        destroyed() {
            clearInterval(this.interval2);
            clearInterval(this.interval);
        },
        methods: {

            autoplay() {

                this.interval = setInterval(() => {
                    this.$nextTick(() => {
                        this.createBarrage()
                    })
                }, 1000)

                this.interval2 = setInterval(this.switchDeck, 60000);
            },

            createBarrage() {
                let k = this.createKey();

                this[this.deckKey][k] = {
                    label: this.barrages.diffRandom(),
                    class: this.heights.diffRandom() + ' ' + this.sizes.diffRandom()
                }

                this.$forceUpdate()
            },


            createKey() {
                return 'A' + (++this.num);
            },
            switchDeck() {

                let k = this.deckKey;
                this.deckKey = k === 'barragesDeck1' ? 'barragesDeck2' : 'barragesDeck1';

                setTimeout(() => {
                    this[k] = {}
                }, 20000)
            },
        }
    }
</script>

<style lang="less" scoped>
    .barrage-container {
        background-image: url("https://static.ycsd.work/site/fashion/barr-bg.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-color: rgba(16, 16, 16, 1);
        height: 1080px;
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .img-title {
        width: 654px;
        position: absolute;
        left: 50%;
        margin-left: -327px;
        top: 395px;
    }

    .t175 {
        top: 175px;
    }

    .t466 {
        top: 466px;
    }

    .t340 {
        top: 340px;
    }

    .t850 {
        top: 850px;
    }

    .t670 {
        top: 670px;
    }

    .barrage {
        padding: 5px 48px;
        font-size: 40px;
        font-weight: 500;
        color: #333333;
        line-height: 56px;
        background: #F9F9F9;
        display: inline-block;
        position: absolute;
        left: 0;
        opacity: 0;

        &:after {
            content: ' ';
            display: block;
            width: 100%;
            height: 100%;
            border: 1px solid #EEEEEE;
            position: absolute;
            top: 10px;
            left: 10px;
        }

        &.small {
            font-size: 32px;
            line-height: 45px;
        }

        animation-name: barrageIn;
        animation-duration: 30s;
        animation-timing-function: linear;
    }

    .size1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
    }

    .size2 {
        font-size: 36px;
        font-weight: 500;
        line-height: 50px;
    }

    .size3 {
        font-size: 40px;
        font-weight: 500;
        line-height: 56px;
    }

    @keyframes barrageIn {
        0% {
            opacity: 1;
            transform: translate(2000px, 0);
        }

        99% {
            opacity: 1;
            transform: translate(-2000px, 0);
        }
        100% {
            opacity: 0;
            transform: translate(-2000px, 0);
        }

    }

</style>